// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("D:\\Labs\\pentafile\\website\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-text-page-jsx": () => import("D:\\Labs\\pentafile\\website\\src\\templates\\TextPage.jsx" /* webpackChunkName: "component---src-templates-text-page-jsx" */),
  "component---src-pages-about-index-jsx": () => import("D:\\Labs\\pentafile\\website\\src\\pages\\about\\index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("D:\\Labs\\pentafile\\website\\src\\pages\\contact\\index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-thank-you-jsx": () => import("D:\\Labs\\pentafile\\website\\src\\pages\\contact\\thank-you.jsx" /* webpackChunkName: "component---src-pages-contact-thank-you-jsx" */),
  "component---src-pages-docs-index-jsx": () => import("D:\\Labs\\pentafile\\website\\src\\pages\\docs\\index.jsx" /* webpackChunkName: "component---src-pages-docs-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("D:\\Labs\\pentafile\\website\\src\\pages\\faq\\index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-index-jsx": () => import("D:\\Labs\\pentafile\\website\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-index-jsx": () => import("D:\\Labs\\pentafile\\website\\src\\pages\\pricing\\index.jsx" /* webpackChunkName: "component---src-pages-pricing-index-jsx" */),
  "component---src-pages-product-index-jsx": () => import("D:\\Labs\\pentafile\\website\\src\\pages\\product\\index.jsx" /* webpackChunkName: "component---src-pages-product-index-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\Labs\\pentafile\\website\\.cache\\data.json")

